import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
// import Accordion from "../components/accordion";
import ImageRight from "../components/imageRight";
import Landing from "../components/landingSection";
import LI from "../components/plan-li";
import Steps from "../DB/affiliate-steps";
import Features from "../DB/affiliate-features";
import Feature from "../components/featureBlock";
// import IndexSlides from "../DB/testmonials_slides";
// import Reviews from "../components/reviews";
// import AccordionContent from "../DB/Accordion-encrypt-protection";
import Image from "../components/imageProcessing";
import Product from "../components/ourProducts";
import ourProducts from "../DB/productsToPromote";
import ReferralTable from "../components/affiliate-table";
// import Popup from "reactjs-popup";

function affiliate() {
//   const [open, setOpen] = useState(false);
//   const closeModal = () => setOpen(false);
  return (
    <Layout>
      <SEO
        keywords={[
          "shared hosting",
          "what is shared hosting",
          "what is a shared hosting",
          "shared hosting vs vpsHosting",
          "what's shared hosting",
          "shared hosting vs cloud hosting",
          "shared hosting best",
          "shared hosting vs wordpressHosting hosting",
          "shared hosting vs dedicated hosting",
          "shared hosting cheap",
          "shared hosting server",
          "shared hosting free",
          "shared hosting cpanel",
          "shared hosting with cpanel",
          "shared hosting provider",
          "shared hosting for wordpressHosting",
          "shared hosting wordpressHosting",
          "shared hosting website",
          "shared hosting linux",
          "shared hosting or vpsHosting",
          "shared hosting price",
          "shared hosting offers",
          "shared hosting nginx",
          "shared hosting ram",
          "what is shared hosting service",
        ]}
        title="Affiliate Program"
        image={"/hostylus-card-preview.jpg"}
        description="Get started with affordable Shared Hosting packages that help take your website to the top with free migration and a 100% guarantee of zero data loss."
      />
      <Landing
        header="Hostylus Affiliate Program"
        desc="Start earning money today by referring customers to our hosting services, completely free! Make pennies without paying a penny as joining the program requires nothing but following the terms and conditions of our hosting service."
        btnURL="#pricing"
        btnText={"join us now"}
        hideSecondary_btn={"hidden"}
        image={
          <Image
            image={"affiliateLanding.png"}
            alt={
              "a group of people using their laptop and happy with Hostylus services"
            }
          />
        }
      />
      <section className="features max-w-6xl mx-auto my-16 mobile:px-3 tablet:px-6 laptop:px-8">
        <h2 className="text-4xl text-black font-bold text-center">
          Just Three Easy Steps To Start Earning
        </h2>
        <h4 className="text-gray-400 text-center text-xl px-16 my-3 mobile:px-6">
          Unleash the technology of your mind, and elevate your business sales
          with our wordpress hosting plans
        </h4>
        <div className="features grid grid-cols-3 gap-3 flex justify-items-stretch mt-6 mobile:grid-cols-1 tablet:grid-cols-1 tablet:px-16 py-8">
          {Steps.map((items, key) => (
            <Feature
              key={key}
              image={items.image}
              header={items.title}
              desc={items.description}
              alt={items.title}
            />
          ))}
        </div>
      </section>

			<ImageRight
				image={<Image image={"how-it-works.png"} alt={" blue settings icon"} />}
				header={"How It Works"}
				desc={
					<ul className="list-disc How-it-works-list">
						<LI
							LI={
								"Simply promote Hostylus on your website with custom banners and links."
							}
							textSize={"text-lg"}
						/>
						<LI
							LI={
								"For every visitor who clicks through these links and signs up, you will receive $65."
							}
							textSize={"text-lg"}
						/>
						<LI
							LI={"Help others learn why Hostylus is so great."}
							textSize={"text-lg"}
						/>
						<LI
							LI={"The more you do so, the more you make!"}
							textSize={"text-lg"}
						/>
					</ul>
				}
				hideShopNow={"hidden"}
				btnLink={"https://client.hostylus.com/register.php"}
				btnText={"get started"}
			/>
			<section className="features max-w-6xl mx-auto my-16 mobile:px-3 tablet:px-4 laptop:px-6">
				<h2 className="text-4xl text-black font-bold text-center">
					What&apos;s Awaiting you?
				</h2>
				<h4 className="text-gray-400 text-center text-xl px-16 my-3">
					Get familiar with the perks that come with it
				</h4>
				<div className="features grid grid-cols-3 gap-3 flex justify-items-center mt-6 mobile:grid-cols-1 py-8 tablet:grid-cols-2 tablet:mx-5">
					{Features.map((items, key) => (
						<Feature
							key={key}
							image={items.image}
							header={items.title}
							desc={items.description}
							alt={items.title}
						/>
					))}
				</div>
			</section>
			{/* <div onClick={() => setOpen((o) => !o)}>
				<Popup open={open} closeOnDocumentClick onClose={closeModal}>
					<div className="modal">
						<a className="close" onClick={closeModal}>
							&times;
						</a>
						<div className="flex  bg-gray-50 mobile:flex-col">
							<div className="w-1/2 px-10 my-auto mobile:text-center tablet:text-center mobile:w-full tablet:w-full">
								<Image image={"form.png"} alt={"form"} />
							</div>
							<form className="w-1/3 mx-10 my-6 mobile:m-4 mobile:w-11/12 tablet:w-full">
								<h3 className="text-black text-2xl mb-2 font-bold capitalize mobile:text-xl">
									Get Registered For Free Now!
								</h3>
								<label
									className="block mb-2 text-xs font-bold uppercase"
									htmlFor="first-name"
								>
									First Name
								</label>

								<input
									className="w-full mb-3 rounded-md border-gray-300"
									id="first-name"
									placeholder="Bill"
									type="text"
								/>

								<label
									className="block mb-2 text-xs font-bold uppercase"
									htmlFor="last-name"
								>
									Last Name
								</label>

								<input
									className="w-full mb-3 rounded-md border-gray-300"
									id="last-name"
									placeholder="Murray"
									type="text"
								/>

								<label
									className="block mb-2 text-xs font-bold uppercase"
									htmlFor="email"
								>
									Email Address
								</label>

								<input
									className="w-full mb-3 rounded-md border-gray-300"
									id="email"
									placeholder="example@mail.com"
									type="email"
								/>
								<label
									className="block mb-2 text-xs font-bold uppercase"
									htmlFor="country"
								>
									Country
								</label>

								<input
									className="w-full mb-3 rounded-md border-gray-300"
									id="country"
									placeholder="jordan"
									type="text"
								/>

								<label
									className="block mb-2 text-xs font-bold uppercase"
									htmlFor="website"
								>
									Website Address
								</label>

								<input
									className="w-full mb-3 rounded-md border-gray-300"
									id="website"
									placeholder="www.example.com"
									type="url"
								/>

								<label
									className="block mb-2 text-xs font-bold uppercase"
									htmlFor="password"
								>
									Password
								</label>

								<input
									className="w-full mb-3 rounded-md border-gray-300"
									id="password"
									placeholder="********"
									type="password"
								/>
								<label
									className="block mb-2 text-xs font-bold uppercase"
									htmlFor="re-password"
								>
									Re-Confirm Password
								</label>

								<input
									className="w-full mb-3 rounded-md border-gray-300"
									id="re-password"
									placeholder="********"
									type="password"
								/>
                <div className="mobile:flex mobile:justify-center">
                  <button className="px-10 py-2 capitalize bg-blue-600 shadow-md text-white rounded-3xl font-semibold text-xl">
									Submit
								</button>
                </div>
								
							</form>
						</div>
					</div>
				</Popup>
			</div> */}
			<section
				id="pricing"
				className="w-4/5 mx-auto my-16 mobile:w-full tablet:mx-auto mobile:p-4 tablet:mx-5"
			>
				<h2 className="text-4xl text-black font-bold text-center">
					How much will you be making?
				</h2>
				<h4 className="text-gray-400 text-center text-xl px-16 mt-3 mb-16 mobile:px-0">
					Check out the commission rates for each plan & service you choose to
					promote
				</h4>
				<ReferralTable
					type="Wordpress Hosting"
					firstPlanName="Startup"
					firstPlanComm="$60"
					secoundPlanName="Growing"
					secoundPlanComm="$90"
					thirdPlanName="Cruising"
					thirdPlanComm="$120"
				/>
				<ReferralTable
					type="Shared Hosting"
					firstPlanName="Rookie"
					firstPlanComm="$25"
					secoundPlanName="Pro"
					secoundPlanComm="$35"
					thirdPlanName="Ace"
					thirdPlanComm="$47"
				/>
				<ReferralTable
					type="Business Hosting"
					firstPlanName="Startup"
					firstPlanComm="$60"
					secoundPlanName="levelup"
					secoundPlanComm="$90"
					thirdPlanName="bossedup"
					thirdPlanComm="$120"
				/>
				<ReferralTable
					type="VPS"
					firstPlanName="All Plans"
					firstPlanComm="30%"
				/>
			</section>
			<section className="h-72 flex flex-wrap content-center justify-center">
				<div className="max-w-4xl text-center">
					<h2 className="text-center text-black text-3xl font-bold capitalize">
						Ready to start making money?
					</h2>
					<p className="text-gray-400 text-lg my-6 mobile:px-4 tablet:px-6">
						All it takes is a few minutes and then you’re all set up to become
						an affiliate member.
					</p>
					<a target="_blank" rel="noreferrer noopener" href="https://client.hostylus.com/register.php">
					<button
						// onClick={() => setOpen((o) => !o)}
						className="px-10 inline py-2 capitalize bg-blue-600 shadow-md ml-1 text-white rounded-3xl font-semibold text-xl"
					>
						Get Started
					</button>
      </a>
					
				</div>
			</section>
			<section className="w-4/5 mx-auto my-16 mobile:w-full tablet:mx-auto mobile:pb-3 tablet:mx-5">
				<h2 className="text-4xl text-black font-bold text-center">
					Choose a Service to promote
				</h2>
				<h4 className="text-gray-400 text-center text-xl px-16 my-3">
					select the affiliate link you want, then copy and paste the code on
					your website, blog or email.
				</h4>
				<div className="grid grid-cols-4 mobile:block tablet:block">
					{ourProducts.map(function (items, index) {
						return (
							<Product
								key={index}
								sub_heading={items.sub_heading}
								header={items.header}
								icon={items.icon}
								buttonLink={items.buttonLink}
							/>
						);
					})}
				</div>
			</section>
			<section className="my-16 h-72 bg-blue-600 flex flex-wrap content-center justify-center">
				<div className="max-w-4xl text-center">
					<h2 className="text-center text-white text-4xl font-bold uppercase">
						Join us for free
					</h2>
					<p className="text-white text-md my-6 mobile:px-4 tablet:px-6">
						We make it easy to sell and get paid.
					</p>
					<a target="_blank" rel="noreferrer noopener" href="https://client.hostylus.com/register.php">

					<button
						// onClick={() => setOpen((o) => !o)}
						className="px-6 inline py-3 uppercase bg-white shadow-md ml-1 text-blue-600 rounded-3xl font-medium text-sm"
					>
						get started
					</button>
					</a>
				</div>
			</section>
			{/* <Reviews slides={IndexSlides} heading="Our Customers Love Us and So Will You" sub_heading="Clients Reviews "/>
      <section className="acc py-16 grid justify-items-center">
        <div className=" px-8 max-w-2xl">
          <h3 className="text-center text-black mb-16 text-4xl font-bold uppercase">
            Entery-level Let’s encrypt protection
          </h3>
          {AccordionContent.map((items, index) => (
            <Accordion
              key={index}
              title={items.title}
              content={items.content}
            />
          ))}
        </div>
      </section>
      <section className="h-72 bg-blue-600 flex flex-wrap content-center justify-center">
        <div className="max-w-4xl text-center">
          <h3 className="text-center text-white text-4xl font-bold uppercase">
            Now What?
          </h3>
          <p className="text-white text-md my-6 mobile:px-4 tablet:px-6">
          Still got things you want to learn about? Ask away & we’ll help with every step!
          </p>
          <a href="#">
            <button className="px-6 inline py-3 uppercase bg-white shadow-md ml-1 text-blue-600 rounded-3xl font-medium text-sm">
            Ask Now
            </button>
          </a>
        </div>
      </section> */}
		</Layout>
	);
}
export default affiliate;
