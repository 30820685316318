import React from "react";
import PropTypes from "prop-types";

function affiliate_tr(props) {
  if(props.type.toUpperCase()==="VPS"){
    return (
      <div className="max-w-2xl mx-auto my-8 relative rounded-sm mobile:w-80 w-custom" style={{boxShadow: "5px 4px 73px 0px #0A1A3E1F"}}>
        <div className="absolute top-2 -left-2.5 -pleft-4 px-3 py-3 w-23-per bg-blue-600 text-white text-center rounded-sm font-bold text-md w-10-per mobile:w-1/5" style={{transform: "rotate(90deg)"}}>{props.type}</div>
    <div className="flex flex-row items-center h-16"> 
      <div className="pl-28 text-2xl w-3/4 mobile:pl-20 ">{props.firstPlanName}</div>
      <div className="text-center font-semibold text-3xl text-blue-600 w-1/4 mobile:text-left">{props.firstPlanComm}</div>
    </div>
        
</div>
    );
  }
    return (
      <div className="max-w-2xl mx-auto my-8 relative rounded-sm mobile:w-80 w-custom" style={{boxShadow: "5px 4px 73px 0px #0A1A3E1F"}}>
        <div className="absolute top-21 -left-21 px-3 py-3 bg-blue-600 text-white text-center rounded-sm font-bold text-md w-29-per mobile:w-3/5 tablet:w-31-per" style={{transform: "rotate(90deg)"}}>{props.type}</div>
    <div className="flex flex-row items-center h-16"> 
      <div className="pl-28 text-2xl w-3/4 mobile:pl-20 ">{props.firstPlanName}</div>
      <div className="text-center font-semibold text-3xl text-blue-600 w-1/4 mobile:text-left">{props.firstPlanComm}</div>
    </div>
    <div className="flex flex-row bg-gray-100 items-center h-16">
      <div className="pl-28 text-2xl w-3/4 mobile:pl-20">{props.secoundPlanName}</div>
      <div className="text-center text-3xl font-semibold text-blue-600 w-1/4 mobile:text-left">{props.secoundPlanComm}</div>
    </div>
    <div className="flex flex-row items-center h-16">
      <div className="pl-28 text-2xl w-3/4 mobile:pl-20">{props.thirdPlanName}</div>
      <div className="text-center text-3xl font-semibold text-blue-600 w-1/4 mobile:text-left">{props.thirdPlanComm}</div>
    </div>
    
</div>
    );

}

export default affiliate_tr;
affiliate_tr.propTypes = {
  type: PropTypes.string.isRequired, // must be a string and defined
  firstPlanName: PropTypes.string.isRequired, // must be a string and defined
  secoundPlanName: PropTypes.string,
  thirdPlanName: PropTypes.string, // must be a string and defined
  firstPlanComm: PropTypes.string.isRequired, // must be a string and defined
  secoundPlanComm: PropTypes.string, // must be a string and defined
  thirdPlanComm: PropTypes.string, // must be a string and defined
};
