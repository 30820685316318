export default [
  {
    image: "sign-up.svg",
    imageAlt: "Affiliate Sign-up",
    title: "Sign-up For Free",
    description:
      "All it takes is a few minutes and then you’re all set up to become an affiliate member.",
  },
  {
    image: "promote-us.svg",
    imageAlt: "Promote us",
    title: "Promote us",
    description:
      "Spread the word about us on your website, or social media platforms & get more potential customers signing up for us through you.",
  },
  {
    image: "start-earning.svg",
    imageAlt: "Start earning",
    title: "Start earning",
    description: "Get your commission for each referral that ends up registering for Hostylus through your customized link.",
  },
];
