export default [
  {
    sub_heading:
      "select the affiliate link you want, then copy and paste the code",
    header: "Wordpress hosting",
    icon: "/wordpress-hosting.svg",
    buttonLink: "https://client.hostylus.com/register.php",
  },
  {
    sub_heading:
      "select the affiliate link you want, then copy and paste the code",
    header: "Business hosting",
    icon: "/Business-hosting.svg",
    buttonLink: "https://client.hostylus.com/register.php",
  },
  {
    sub_heading:
      "select the affiliate link you want, then copy and paste the code",
    header: "Shared hosting",
    icon: "/shared-hosting.svg",
    buttonLink: "https://client.hostylus.com/register.php",
  },
  {
    sub_heading:
      "select the affiliate link you want, then copy and paste the code",
    header: "VPS",
    icon: "/vps.svg",
    buttonLink: "https://client.hostylus.com/register.php",
  },
];
