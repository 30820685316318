export default [
  {
    image: "free-to-join.svg",
    title: "Zero Cost to Join",
    description:"Join our affiliate program & sign up for free, you won’t have to pay for anything to be included on the program list.",
  },
  {
    image: "Reliable-Tracking.svg",
    title: "Accurate Tracking",
    description:
      "We manage detailed & accurate tracking of every purchase coming from your customized affiliate link even if users don’t purchase immediately from your link.  It’s all cookies tracked so don’t stress about mistakes happening.",
  },

  {
    image: "Dedicated-Team.svg",
    title: "Devoted Support Team",
    description:
      "For any further help you can reach out to the support team who’ll happily assist you.",
  },
  {
    image: "Unlimited-Earnings.svg",
    title: "More Earnings",
    description:
      "There is no limit to how much you earn. The more purchases you benefit us with, the more money you’re gonna be making for each service that you promote.",
  },
  {
    image: "customized-Campaigns.svg",
    title: "Customized Campaigns",
    description:
      "Use your tracking links to segment referred traffic and monitor how different activity performs. Compare campaigns by clicks, conversions, and more.",
  },
  {
    image: "xkoadikjxm.svg",
    title: "Low Withdrawal Limit ($15)",
    description:
      "Unlike other websites, our limit for minimum withdrawal is really low for $15 only. This means you won’t have to make a fortune to be eligible for withdrawal.",
  },
];
