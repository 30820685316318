import React from "react";
import PropTypes from "prop-types";
// import { Link } from "gatsby";

export default function ourProducts(propss) {
	return (
		<div className="mx-3 text-center p-4 border-solid border-gray-200 border-2 rounded-lg my-16 hover:border-blue-600">
			<div>
				<img
					className="mx-auto"
					src={propss.icon}
					alt={` ${propss.header} icon`}
				></img>
			</div>
			<h4 className="text-black text-2xl mobile:text-2xl text-center font-extrabold">
				{propss.header}
			</h4>
			<div className="px-2 my-6">
				<p className="text-gray-500 text-lg">{propss.sub_heading}</p>
			</div>
			<div className=" text-center my-3">
				<a href={propss.buttonLink} rel="noreferrer noopener" target="_blank">
				<button className="bg-blue-600 text-xl font-bold uppercase px-12 text-white py-3 m-auto rounded-full  ">
					Register Now
				</button>
				</a>
			</div>
		</div>
	);
}
ourProducts.PropTypes = {
	sub_heading: PropTypes.string.isRequired, // must be a number and defined
	header: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	buttonLink: PropTypes.string,
};
